var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom mt-4 d-flex flex-column" }, [
    _c("div", { staticClass: "mb-4" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.username,
            expression: "username",
          },
        ],
        staticClass: "uk-input",
        attrs: {
          type: "text",
          name: "username",
          placeholder: _vm.translations.email,
        },
        domProps: { value: _vm.username },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.username = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.usernameErrors.length > 0
        ? _c(
            "div",
            { staticClass: "input-errors" },
            _vm._l(_vm.usernameErrors, function (error) {
              return _c("p", [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-3" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password",
          },
        ],
        staticClass: "uk-input",
        attrs: {
          type: "password",
          name: "password",
          placeholder: _vm.translations.password,
        },
        domProps: { value: _vm.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.password = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.passwordErrors.length > 0
        ? _c(
            "div",
            { staticClass: "input-errors" },
            _vm._l(_vm.passwordErrors, function (error) {
              return _c("p", [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-width-1-1 d-flex justify-content-end" }, [
      _c("p", { staticClass: "my-0 text--small" }, [
        _c(
          "a",
          {
            staticClass: "password-link",
            attrs: { href: _vm.routes.register },
          },
          [_vm._v(_vm._s(_vm.translations.account_request))]
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mx-2 mt-0 mb-1 password-stripe" }, [_vm._v("/")]),
      _vm._v(" "),
      _c("p", { staticClass: "my-0 text--small" }, [
        _c(
          "a",
          {
            staticClass: "password-link",
            attrs: { href: _vm.routes.password_forgotten },
          },
          [_vm._v(_vm._s(_vm.translations.forgot_password))]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-width-1-1 mt-4 " }, [
      _c(
        "div",
        { staticClass: "d-flex flex-sm-row flex-column align-items-sm-center" },
        [
          _c(
            "button",
            {
              staticClass:
                "uk-button uk-button-primary mb-sm-0 mb-4 uk-width-1-1",
              attrs: { type: "submit" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin.apply(null, arguments)
                },
              },
            },
            [
              _vm.isLoading
                ? _c("div", { staticClass: "loader" }, [
                    _c("i", {
                      staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                    }),
                  ])
                : _vm._e(),
              _vm._v(
                "\n        " + _vm._s(_vm.translations.login) + "\n      "
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }