var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-custom uk-grid-small", attrs: { "uk-grid": "" } },
    [
      _c("div", { staticClass: "uk-width-1-1@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "companyname" } },
          [_vm._v(_vm._s(_vm.translations.company.companyname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.companyname,
                expression: "companyname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "companyname",
              type: "text",
              placeholder: _vm.translations.company.companyname,
            },
            domProps: { value: _vm.companyname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.companyname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.companynameErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.companynameErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "companyvat" } },
          [_vm._v(_vm._s(_vm.translations.company.vat) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.companyvat,
                expression: "companyvat",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "companyvat",
              type: "text",
              placeholder: _vm.translations.company.vat,
            },
            domProps: { value: _vm.companyvat },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.companyvat = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.companyvatErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.companyvatErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "lastname" } },
          [_vm._v(_vm._s(_vm.translations.lastname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.lastname,
                expression: "lastname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "lastname",
              type: "text",
              placeholder: _vm.translations.lastname,
            },
            domProps: { value: _vm.lastname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.lastname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.lastnameErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.lastnameErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "firstname" } },
          [_vm._v(_vm._s(_vm.translations.firstname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.firstname,
                expression: "firstname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "firstname",
              type: "text",
              placeholder: _vm.translations.firstname,
            },
            domProps: { value: _vm.firstname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.firstname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.firstnameErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.firstnameErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.translations.email) + " *"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "email",
              type: "email",
              placeholder: _vm.translations.email,
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.emailErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.emailErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "password" } },
          [_vm._v(_vm._s(_vm.translations.password) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "password",
              type: "password",
              placeholder: _vm.translations.password,
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.passwordErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.passwordErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-sm-5 mt-4" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary uk-width-1-1",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleCreate.apply(null, arguments)
              },
            },
          },
          [
            _vm.isLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v(
              "\n      " + _vm._s(_vm.translations.create_account) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }