var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-md-0 mb-5" }, [
    _c(
      "a",
      {
        staticClass: "product",
        attrs: { href: _vm.product.slug, title: _vm.product.name },
      },
      [
        _c("span", { staticClass: "product__header" }, [
          _c("div", { staticClass: "product__image--container" }, [
            _c("div", {
              staticClass: "product__image lazyload",
              attrs: { "data-bgset": _vm.imageUrl, alt: _vm.product.name },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__info" }, [
          _c("p", { staticClass: "product__title" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _vm.product.description != null && _vm.product.description != ""
            ? _c("p", {
                staticClass: "body--small uk-text-center",
                domProps: { innerHTML: _vm._s(_vm.product.description) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "mt-2 mb-2 body--small" }, [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.product.width) +
                  "m x " +
                  _vm._s(_vm.product.height) +
                  "m (" +
                  _vm._s(_vm.product.surface) +
                  "m²)"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-0 mb-2 body--small" }, [
            _c("strong", [
              _vm._v(
                "€ " +
                  _vm._s(_vm.product.formatted.use_price) +
                  " " +
                  _vm._s(_vm.translations.excl_vat)
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }