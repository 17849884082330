var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "address-box" }, [
      _c("section", [
        _c("p", { staticClass: "my-0" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.address.street) +
              " " +
              _vm._s(_vm.address.number) +
              _vm._s(_vm.address.number_addon) +
              " " +
              _vm._s(_vm.address.busnumber) +
              " "
          ),
          _c("br"),
          _vm._v(
            "\n                " +
              _vm._s(_vm.address.postalcode) +
              " " +
              _vm._s(_vm.address.commune) +
              ", "
          ),
          _c("br"),
          _vm._v(
            "\n                " +
              _vm._s(_vm.address.country.name) +
              "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleStartEdit.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "fa-solid fa-pencil fa-fw me-1" }),
            _vm._v(_vm._s(_vm.translations.edit_address)),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleTrash.apply(null, arguments)
              },
            },
          },
          [
            _vm.trashIsLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin fa-fw me-1",
                  }),
                ])
              : _c("i", { staticClass: "fa-solid fa-trash fa-fw me-1" }),
            _vm._v(
              "\n              " +
                _vm._s(_vm.translations.delete_address) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.address.id === _vm.defaultDelivery
          ? _c(
              "a",
              {
                staticClass: "no-click",
                attrs: { href: "javascript:void(0)" },
              },
              [
                _c("i", { staticClass: "fa-solid fa-house fa-fw me-1" }),
                _vm._v(_vm._s(_vm.translations.current_default_delivery)),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.address.id === _vm.defaultBilling
          ? _c(
              "a",
              {
                staticClass: "no-click",
                attrs: { href: "javascript:void(0)" },
              },
              [
                _c("i", {
                  staticClass: "fa-solid fa-file-invoice-dollar fa-fw me-1",
                }),
                _vm._v(_vm._s(_vm.translations.current_default_billing)),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-modal-container uk-flex-top",
        attrs: {
          id: _vm.editModalId,
          "uk-modal": "esc-close: false; bg-close: false; keyboard: false;",
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "uk-modal-dialog uk-modal-body uk-margin-auto-vertical",
          },
          [
            _c(
              "form",
              {
                staticClass: "form-custom form-custom-white",
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleUpdate.apply(null, arguments)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "uk-grid-small", attrs: { "uk-grid": "" } },
                  [
                    _c("div", { staticClass: "uk-width-1-2@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "streetname" },
                        },
                        [_vm._v(_vm._s(_vm.translations.streetname))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.street,
                              expression: "editAddress.street",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "streetname",
                            type: "text",
                            placeholder: _vm.translations.streetname,
                          },
                          domProps: { value: _vm.editAddress.street },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "street",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.street
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(_vm.editErrors.street, function (error) {
                                return _c("p", [_vm._v(_vm._s(error))])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "number" },
                        },
                        [_vm._v(_vm._s(_vm.translations.housenr))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.number,
                              expression: "editAddress.number",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "number",
                            type: "text",
                            placeholder: _vm.translations.housenr,
                          },
                          domProps: { value: _vm.editAddress.number },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "number",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.number
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(_vm.editErrors.number, function (error) {
                                return _c("p", [_vm._v(_vm._s(error))])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "number_addon" },
                        },
                        [_vm._v(_vm._s(_vm.translations.number_addon_short))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.number_addon,
                              expression: "editAddress.number_addon",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "number_addon",
                            type: "text",
                            placeholder: _vm.translations.number_addon_short,
                          },
                          domProps: { value: _vm.editAddress.number_addon },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "number_addon",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.number_addon
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.editErrors.number_addon,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "busnumber" },
                        },
                        [_vm._v(_vm._s(_vm.translations.busnr))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.busnumber,
                              expression: "editAddress.busnumber",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "busnumber",
                            type: "text",
                            placeholder: "Busnr.",
                          },
                          domProps: { value: _vm.editAddress.busnumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "busnumber",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.busnr
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(_vm.editErrors.busnr, function (error) {
                                return _c("p", [_vm._v(_vm._s(error))])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-4@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "postalcode" },
                        },
                        [_vm._v(_vm._s(_vm.translations.postalcode))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.postalcode,
                              expression: "editAddress.postalcode",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "postalcode",
                            type: "text",
                            placeholder: _vm.translations.postalcode,
                          },
                          domProps: { value: _vm.editAddress.postalcode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "postalcode",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.postalcode
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.editErrors.postalcode,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-2@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "commune" },
                        },
                        [_vm._v(_vm._s(_vm.translations.commune))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editAddress.commune,
                              expression: "editAddress.commune",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "commune",
                            type: "text",
                            placeholder: _vm.translations.commune,
                          },
                          domProps: { value: _vm.editAddress.commune },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editAddress,
                                "commune",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.editErrors.commune
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(_vm.editErrors.commune, function (error) {
                                return _c("p", [_vm._v(_vm._s(error))])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-4@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "country" },
                        },
                        [_vm._v(_vm._s(_vm.translations.country))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editAddress.country_id,
                                expression: "editAddress.country_id",
                              },
                            ],
                            staticClass: "custom-select uk-width-1-1",
                            attrs: { name: "company-type", id: "country" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.editAddress,
                                  "country_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.countries, function (country) {
                            return _c(
                              "option",
                              { domProps: { value: country.id } },
                              [_vm._v(_vm._s(country.name))]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.editErrors.country_id
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.editErrors.country_id,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-1 mb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-margin uk-grid-small uk-child-width-auto uk-grid",
                        },
                        [
                          _c("p", [
                            _c("span", { staticClass: "uk-text-bold" }, [
                              _vm._v(
                                _vm._s(_vm.translations.default_address) + "s"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "custom-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editDefaultDelivery,
                                  expression: "editDefaultDelivery",
                                },
                              ],
                              staticClass: "uk-checkbox me-2 mt-1",
                              attrs: {
                                type: "checkbox",
                                name: "default_delivery",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.editDefaultDelivery)
                                  ? _vm._i(_vm.editDefaultDelivery, null) > -1
                                  : _vm.editDefaultDelivery,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.editDefaultDelivery,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.editDefaultDelivery = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.editDefaultDelivery = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.editDefaultDelivery = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "my-0" }, [
                              _vm._v(_vm._s(_vm.translations.default_delivery)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.editErrors.default_delivery
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.editErrors.default_delivery,
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("label", { staticClass: "custom-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editDefaultBilling,
                                  expression: "editDefaultBilling",
                                },
                              ],
                              staticClass: "uk-checkbox me-2 mt-1",
                              attrs: {
                                type: "checkbox",
                                name: "default_billing",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.editDefaultBilling)
                                  ? _vm._i(_vm.editDefaultBilling, null) > -1
                                  : _vm.editDefaultBilling,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.editDefaultBilling,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.editDefaultBilling = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.editDefaultBilling = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.editDefaultBilling = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "my-0" }, [
                              _vm._v(_vm._s(_vm.translations.default_billing)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.editErrors.default_billing
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.editErrors.default_billing,
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-1" }, [
                      _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c(
                          "button",
                          {
                            staticClass: "uk-button uk-button-secondary me-2",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancelUpdate.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations.cancel))]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "uk-button uk-button-primary",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleUpdate.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm.editIsLoading
                              ? _c("div", { staticClass: "loader" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa-solid fa-circle-notch fa-spin me-2",
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.translations.save) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }