<template>
	<form class="form-custom">
		<ul class="sidebar" uk-accordion="multiple: true">
			<!-- ==========[ SUB CATEGORIES ]========== -->
			<filter-sidebar-categories 
				v-if="categories.length > 0" 
				:categories="categories"
				:activeCategories="activeCategories" 
				:translations="translations"
			>
			</filter-sidebar-categories>

			<!-- ==========[ COLOS ]========== -->
			<!-- <filter-sidebar-colors
                v-if="colors.length > 0"
                v-model="activeColors"
                :colors="colors"
                :translations="translations"
                :active-colors="activeColors"
            >
            </filter-sidebar-colors> -->

			<!-- ==========[ BRANDS ]========== -->
			<!-- <filter-sidebar-brands
                v-if="brands.length > 0"
                v-model="activeBrands"
                :brands="brands"
                :translations="translations"
                :active-brands="activeBrands"
            >
            </filter-sidebar-brands> -->

			<!-- ==========[ PRICE ]========== -->
			<!-- <li class="uk-open">
                <a class="uk-accordion-title" href="#">
                    <span>Prijs</span>
                    <i class="fa-solid fa-chevron-down"></i>
                </a>

                <div class="uk-accordion-content">
                    <div class="sidebar__price">
                        <p class="my-0 me-2">&euro;</p>
                        <input type="number" v-model="minPrice" step="0.01" name="minp">
                        <p class="my-0 mx-2">tot</p>
                        <input type="number" v-model="maxPrice" step="0.01" name="maxp">
                        <button type="submit" @click.prevent="pushQueryString"><i class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </li> -->

			<!-- ==========[ SIZES ]========== -->
			<filter-sidebar-sizes-group 
				v-for="sizegroup in sizes" 
				v-model="activeSizes" 
				:sizegroup="sizegroup"
				:key="sizegroup.id" 
				:active-sizes="activeSizes"
			>
			</filter-sidebar-sizes-group>

			<filter-sidebar-dynamic-filters 
				v-for="filter in dynamicfilters" 
				v-model="activeDynamicalFilters"
				:filter="filter" 
				:key="filter.id" 
				:active-filters="activeDynamicalFilters"
			>
			</filter-sidebar-dynamic-filters>
		</ul>
	</form>
</template>

<script>
import * as functions from "../functions.js";
import { EventBus } from "../EventBus";
import VueCookies from 'vue-cookies'


export default {
	name: "FilterSidebar",
	mounted() {
		EventBus.$on('MOBILE_FILTERS_CHANGED', prop => {
			this[prop.changedVar] = {};
			this[prop.changedVar] = prop.newValues;
		});

		EventBus.$on('MOBILE_PRICE_FILTERS_CHANGED', () => {
			this.pushQueryString();
		});

		EventBus.$on('CATEGORIES_CHANGED', (cat) => {
			// if(cat.level == 0) {
			// 	this.activeCategories = [];
			// }
			let activeCategoriestest = {};
			let found = false;
			for (const [key, value] of Object.entries(this.activeCategories)) {
				 if (key == cat.id) {
					found = true;
					continue;
				} 

				if(cat.level == 0 ) {
					this.activeCategories = [];
					activeCategoriestest = {};
				} else {
				activeCategoriestest[key] = value;

				}
			}
			
			if (!found) {
				activeCategoriestest[cat.id] = true;
			}

			this.activeCategories = activeCategoriestest;

		});

		// enable all active categories on refresh
		// this is not the proper way to do this in vue, but it works
		for (const [key, value] of Object.entries(this.activeCategories)) {
			if (value) {
				document.querySelector(`#cat-${key}`).checked = true;
			}
		}
	},
	data() {
		return {
			colors: this.initColors,
			brands: this.initBrands,
			sizes: this.initSizes,
			dynamicfilters: this.initDynamicfilters,
			activeBrands: this.initActive(this.getUrlFilter('b', [])),
			activeSizes: this.initActive(this.getUrlFilter('s', [])),
			activeDynamicalFilters: this.initActive(this.getUrlFilter('d', [])),
			activeColors: this.initActive(this.getUrlFilter('c', [])),
			activeCategories: this.initActive(this.getUrlFilter('e', [])),
			minPrice: this.getUrlFilter('minp', null),
			maxPrice: this.getUrlFilter('maxp', null)
		}
	},
	methods: {
		initActive(initProp) {
			let obj = {};
			initProp.forEach(key => {
				obj[key] = true;
			})

			return obj;
		},
		pushQueryString() {
			let queryString = this.filtersQuery;
			if (queryString != '') {
				queryString += ",";
			}

			if (this.minPrice != null && this.minPrice > 0) {
				queryString += "minp:" + this.minPrice + ",";
			}

			if (this.maxPrice != null && this.maxPrice > 0) {
				queryString += "maxp:" + this.maxPrice + ",";
			}

			functions.pushUrlParameter("filters", this.encodeQueryString(queryString));
			functions.pushUrlParameter("page", "");
			            VueCookies.set('filter_query' , this.encodeQueryString(queryString), "1h") 

			this.refreshPage();
		},
		encodeQueryString(queryString) {
			if (queryString != '') {
				queryString = encodeURIComponent(window.btoa(queryString.slice(0, -1)));
			}

			return queryString;
		},
		getUrlFilter(which, defaultReturn) {
			let params = functions.getUrlParameters().filters;

			if (params != undefined) {
				if (params.slice(-1) == "#") {
					params = params.slice(0, -1);
				}

				params = decodeURIComponent(params);
				params = window.atob(params);
				params = functions.stringToArray(params);

				let returnSelfParams = ["minp", "maxp"];
				if (returnSelfParams.includes(which)) {
					if (params[which]) {
						return params[which];
					}
				} else {
					if (params[which]) {
						return params[which].split('|');
					}
				}
			}

			return defaultReturn;
		},
		refreshPage() {
			let route = this.routes.filtering.reRender + functions.buildQueryString(functions.getUrlParameters());

			window.axios.get(route)
				.then(res => {
					this.colors = {};
					this.colors = res.data.colors;
					this.brands = {};
					this.brands = res.data.brands;
					this.sizes = Object.assign({}, res.data.sizes);
					this.dynamicfilters = Object.assign({}, res.data.dynamic_filters);

					EventBus.$emit('RELOAD_PRODUCTS', res.data.products);
				});
		}
	},
	computed: {
		filtersQuery: function () {
			let brandsString = '';
			let sizesString = '';
			let dynamicFiltersString = '';
			let colorsString = '';
			let categoriesString = '';
			let queryString = '';

			for (const [key, value] of Object.entries(this.activeBrands)) {
				if (value) {
					brandsString += key + "|";
				}
			}

			for (const [key, value] of Object.entries(this.activeSizes)) {
				if (value) {
					sizesString += key + "|";
				}
			}

			for (const [key, value] of Object.entries(this.activeDynamicalFilters)) {
				if (value) {
					dynamicFiltersString += key + "|";
				}
			}

			for (const [key, value] of Object.entries(this.activeColors)) {
				if (value) {
					colorsString += key + "|";
				}
			}

			for (const [key, value] of Object.entries(this.activeCategories)) {
				if (value) {
					categoriesString += key + "|";
				}
			}

			if (brandsString != '') {
				brandsString = "b:" + brandsString.slice(0, -1);
				queryString += brandsString + ",";
			}

			if (sizesString != '') {
				sizesString = "s:" + sizesString.slice(0, -1);
				queryString += sizesString + ",";
			}

			if (dynamicFiltersString != '') {
				dynamicFiltersString = "d:" + dynamicFiltersString.slice(0, -1);
				queryString += dynamicFiltersString + ",";
			}

			if (colorsString != '') {
				colorsString = "c:" + colorsString.slice(0, -1);
				queryString += colorsString + ",";
			}

			if (categoriesString != '') {
				categoriesString = "e:" + categoriesString.slice(0, -1);
				queryString += categoriesString + ",";
			}

			return queryString.slice(0, -1);
		}
	},
	watch: {
		filtersQuery: function (val, oldVal) {
			this.pushQueryString();
		}
	},
	props: {
		category: {
			type: Object | Array,
			required: true
		},
		categories: {
			type: Object | Array,
			required: true
		},
		initColors: {
			type: Object | Array,
			required: true
		},
		initBrands: {
			type: Object | Array,
			required: true
		},
		initSizes: {
			type: Object | Array,
			required: true
		},
		initDynamicfilters: {
			type: Object | Array,
			required: true
		},
		translations: {
			type: Object | Array,
			required: true
		},
		routes: {
			type: Object | Array,
			required: true
		}
	}
}
</script>

<style scoped></style>
