<template>
  <form class="form-custom uk-grid-small" uk-grid>

    <!-- <div class="uk-width-1-1 mb-3">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.account_type.self }}</span></p>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="accountType" value="private" v-model="accountType">
          <p class="my-0">{{ translations.account_type.private }}</p>
        </label>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="accountType" value="business" v-model="accountType">
          <p class="my-0">{{ translations.account_type.company }}</p>
        </label>

        <div class="input-errors" v-if="accountTypeErrors.length > 0">
          <p v-for="error in accountTypeErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-2@s" v-if="accountType == 'business'">
      <label class="uk-form-label" for="companyname">{{ translations.company.companyname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyname" type="text" :placeholder="translations.companyname" v-model="companyname">

        <div class="input-errors" v-if="companynameErrors.length > 0">
          <p v-for="error in companynameErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-4@s" v-if="accountType == 'business'">
      <label class="uk-form-label" for="companytype">{{ translations.company.type }} *</label>
      <div class="uk-form-controls">
        <select name="company-type" id="companytype" class="custom-select uk-width-1-1" v-model="companyType">
          <option v-for="legalForm in legalForms" :value="legalForm">{{ legalForm }}</option>
        </select>

        <div class="input-errors" v-if="companyTypeErrors.length > 0">
          <p v-for="error in companyTypeErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    

    <!-- <div class="uk-width-1-1 mb-3 mt-4">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.salutation.self }}</span></p>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="salutation" value="male" v-model="salutation">
          <p class="my-0">{{ translations.salutation.male }}</p>
        </label>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="salutation" value="female" v-model="salutation">
          <p class="my-0">{{ translations.salutation.female }}</p>
        </label>

        <div class="input-errors" v-if="salutationErrors.length > 0">
          <p v-for="error in salutationErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <div class="uk-width-1-1@s">
      <label class="uk-form-label" for="companyname">{{ translations.company.companyname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyname" type="text" :placeholder="translations.company.companyname" v-model="companyname">

        <div class="input-errors" v-if="companynameErrors.length > 0">
          <p v-for="error in companynameErrors">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1@s" >
      <label class="uk-form-label" for="companyvat">{{ translations.company.vat }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyvat" type="text" :placeholder="translations.company.vat" v-model="companyvat">
        <div class="input-errors" v-if="companyvatErrors.length > 0">
          <p v-for="error in companyvatErrors">{{ error }}</p>
        </div>
      </div>
    </div>

     <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="lastname">{{ translations.lastname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="lastname" type="text" :placeholder="translations.lastname" v-model="lastname">
        <div class="input-errors" v-if="lastnameErrors.length > 0">
          <p v-for="error in lastnameErrors">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="firstname">{{ translations.firstname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="firstname" type="text" :placeholder="translations.firstname" v-model="firstname">
        <div class="input-errors" v-if="firstnameErrors.length > 0">
          <p v-for="error in firstnameErrors">{{ error }}</p>
        </div>
      </div>
    </div>

   

    <!-- <div class="uk-width-1-1">
      <label class="uk-form-label" for="phone" v-if="userPhoneRequired">{{ translations.tel_or_cell }} *</label>
      <label class="uk-form-label" for="phone" v-else>{{ translations.tel_or_cell }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="phone" type="text" :placeholder="translations.tel_or_cell" v-model="phone">
        <div class="input-errors" v-if="phoneErrors.length > 0">
          <p v-for="error in phoneErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="street">{{ translations.address.streetname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="street" type="text" :placeholder="translations.address.streetname" v-model="street">
        <div class="input-errors" v-if="streetErrors.length > 0">
          <p v-for="error in streetErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-6@s">
      <label class="uk-form-label" for="number">{{ translations.address.housenr }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="number" type="text" :placeholder="translations.address.housenr" v-model="number">
        <div class="input-errors" v-if="numberErrors.length > 0">
          <p v-for="error in numberErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-6@s">
      <label class="uk-form-label" for="number_addon">{{ translations.address.number_addon_short }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="number_addon" type="text" :placeholder="translations.address.number_addon_short" v-model="number_addon">
        <div class="input-errors" v-if="numberAddonErrors.length > 0">
          <p v-for="error in numberAddonErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-6@s">
      <label class="uk-form-label" for="busnr">{{ translations.address.busnr }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="busnr" type="text" :placeholder="translations.address.busnr" v-model="busnr">
        <div class="input-errors" v-if="busnrErrors.length > 0">
          <p v-for="error in busnrErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-4@s">
      <label class="uk-form-label" for="postalcode">{{ translations.address.postalcode }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="postalcode" type="text" :placeholder="translations.address.postalcode" v-model="postalcode">
        <div class="input-errors" v-if="postalcodeErrors.length > 0">
          <p v-for="error in postalcodeErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="commune">{{ translations.address.commune }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="commune" type="text" :placeholder="translations.address.commune" v-model="commune">
        <div class="input-errors" v-if="communeErrors.length > 0">
          <p v-for="error in communeErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-4@s">
      <label class="uk-form-label" for="country">{{ translations.address.country }} *</label>
      <div class="uk-form-controls">
        <select name="company-type" id="country" class="custom-select uk-width-1-1" v-model="country">
          <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
        </select>
        <div class="input-errors" v-if="countryErrors.length > 0">
          <p v-for="error in countryErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="uk-width-1-1 mt-4">
      <h3 class="uk-text-primary">{{ translations.login_data }}</h3>
      <p class="uk-text-small">
        {{ translations.login_data_expl }}
      </p>
    </div> -->

    <div class="uk-width-1-1">
      <label class="uk-form-label" for="email">{{ translations.email }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="email" type="email" :placeholder="translations.email" v-model="email">
        <div class="input-errors" v-if="emailErrors.length > 0">
          <p v-for="error in emailErrors">{{ error }}</p>
        </div>
      </div>
    </div>

		
    <div class="uk-width-1-1@s">
      <label class="uk-form-label" for="password">{{ translations.password }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="password" type="password" :placeholder="translations.password" v-model="password">
        <div class="input-errors" v-if="passwordErrors.length > 0">
          <p v-for="error in passwordErrors">{{ error }}</p>
        </div>
      </div>
    </div>

    <!-- <div class="uk-width-1-3@s">
      <label class="uk-form-label" for="confirm_password">{{ translations.password_confirm }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="confirm_password" type="password" :placeholder="translations.password_confirm" v-model="confirmPassword">
        <div class="input-errors" v-if="confirmPasswordErrors.length > 0">
          <p v-for="error in confirmPasswordErrors">{{ error }}</p>
        </div>
      </div>
    </div> -->
		

    <div class="uk-width-1-1 mt-sm-5 mt-4">
      <button type="submit" @click.prevent="handleCreate" class="uk-button uk-button-primary uk-width-1-1">
        <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
        {{ translations.create_account }}
      </button>
    </div>
  </form>
</template>

<script>
import {EventBus} from "../EventBus";

export default {
  name: "RegisterForm",
  mounted() {
  },
  data(){
    return {
      // accountType: 'business',
      // accountTypeErrors: {},
      companyname: '',
      companynameErrors: {},
      // companyType: '',
      // companyTypeErrors: {},
      companyvat: '',
      companyvatErrors: {},
      // salutation: '',
      // salutationErrors: '',
      // username: '',
      // usernameErrors: {},
      firstname: '',
      firstnameErrors: {},
      lastname: '',
      lastnameErrors: {},
      email: '',
      emailErrors: {},
      password: '',
      passwordErrors: {},
      isLoading: false
    }
  },
  methods: {
    handleCreate(){
      this.isLoading = true;

      let postData = {
        // account_type: this.accountType,
        companyname: this.companyname,
        // companytype: this.companyType,
        companyvat: this.companyvat,
        // salutation: this.salutation,
        // username: this.username,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
      };

      window.axios.post(this.routes.register, postData)
          .then(res => {
            
            this.companynameErrors = {};
            this.companyvatErrors = {};
            this.firstnameErrors = {};
            this.lastnameErrors = {};
            this.emailErrors = {};
            this.passwordErrors = {};

            let resData = res.data;
            if(resData.success)
            {
              window.location.href = this.routes.success_redirect;
            }
            else
            {
              EventBus.$emit('NOTIFY', {message: resData.message, status: 'danger', position: 'top-right', 'timeout': 3000});
              this.isLoading = false;
            }
          })
          .catch(err => {
            
            this.companynameErrors = {};
            this.companyvatErrors = {};
            this.firstnameErrors = {};
            this.emailErrors = {};
            this.passwordErrors = {};
            this.companynameErrors = err?.response?.data?.companyname ?? {};
            this.companyvatErrors = err?.response?.data?.companyvat ?? {};
            this.firstnameErrors = err?.response?.data?.firstname ?? {};
            this.lastnameErrors = err?.response?.data?.lastname ?? {};
            this.emailErrors = err?.response?.data?.email ?? {};
            this.passwordErrors = err?.response?.data?.password ?? {};

            EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});
            this.isLoading = false;
          });
    }
  },
  props: {
    translations: {
      type: Object|Array,
      required: true
    },
    routes: {
      type: Object|Array,
      required: true
    },
    countries: {
      type: Object|Array,
      required: true
    },
    userPhoneRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    legalForms: {
      type: Object|Array,
      required: false,
      default() { return []; }
    },
    defaultCountry: {
      type: Number,
      required: true,
      default: null,
    }
  }
}
</script>

<style scoped>

</style>
