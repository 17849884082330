var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal modal--big", attrs: { hidden: _vm.hidden } },
    [
      _c("div", { staticClass: "modal__box" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-md-start justify-content-center mb-sm-5 mb-4",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass:
                  "modal--title my-0 uk-text-break uk-text-left@s uk-text-center",
              },
              [_vm._v(_vm._s(_vm.translations.add_product_modal.added))]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product-row" }, [
          _c("div", [
            _c("div", {
              staticClass: "image-block uk-position-relative mb-3 mb-md-0",
              attrs: { "data-src": _vm.imageUrl, "uk-img": "" },
            }),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-content-center align-items-start-md uk-height-1-1 uk-text-center",
              },
              [
                _c("h4", { staticClass: "uk-text-bold my-0 uk-text-break" }, [
                  _vm._v(_vm._s(_vm.quantityString) + _vm._s(_vm.product.name)),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-content-center align-items-start-md uk-height-1-1 uk-text-center",
              },
              [
                _vm.discountActive
                  ? _c("div", { staticClass: "image-banner pt-3 pt-md-0" }, [
                      _vm._v(_vm._s(_vm.discountPrice)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h3", { staticClass: "my-md-0 mt-2" }, [
                  _vm._v("€ " + _vm._s(_vm.totalPrice)),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-md-column flex-sm-row flex-column justify-content-md-center align-items-md-end justify-content-center align-items-center uk-height-1-1 uk-child-width1-1",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "uk-button uk-button-primary mb-md-2 mb-sm-0 mb-2 me-md-0 me-sm-2 me-0",
                    attrs: { href: _vm.routes.add_products_modal.cart },
                  },
                  [_vm._v(_vm._s(_vm.translations.add_product_modal.to_cart))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "uk-button uk-button-secondary",
                    on: { click: _vm.hideModal },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.translations.add_product_modal.continue_shopping
                      )
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "modal--title my-0 me-4 uk-visible@s" }, [
      _c("i", { staticClass: "fa-solid fa-check text--success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }