var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "formSection" }, [
    _c(
      "form",
      { ref: "form", staticClass: "uk-form-stacked form-custom" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.translations.form_title.toUpperCase()))]),
        _vm._v(" "),
        _vm._l(_vm.messages, function (message) {
          return _c(
            "div",
            {
              class: "uk-alert-" + message.type,
              attrs: { "uk-alert": "", "uk-alert": "" },
            },
            [
              _c("a", {
                staticClass: "uk-alert-close",
                attrs: { "uk-close": "" },
              }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(message.message))]),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.hideForm
          ? _c("div", { staticClass: "d-flex flex-column" }, [
              _c("div", { staticClass: "uk-margin" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1@s mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.lastname, function (error) {
                      return _vm.errors.lastname
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lastname,
                          expression: "lastname",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      class: { "uk-form-danger": _vm.errors.lastname },
                      attrs: {
                        type: "text",
                        id: "lastname",
                        name: "lastname",
                        placeholder: _vm.translations.lastname,
                      },
                      domProps: { value: _vm.lastname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.lastname = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.lastname },
                        attrs: { for: "lastname" },
                      },
                      [_vm._v(_vm._s(_vm.translations.lastname) + " *")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1@s mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.firstname, function (error) {
                      return _vm.errors.firstname
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.firstname,
                          expression: "firstname",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      class: { "uk-form-danger": _vm.errors.firstname },
                      attrs: {
                        type: "text",
                        id: "firstname",
                        name: "firstname",
                        placeholder: _vm.translations.firstname,
                      },
                      domProps: { value: _vm.firstname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.firstname = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.firstname },
                        attrs: { for: "firstname" },
                      },
                      [_vm._v(_vm._s(_vm.translations.firstname) + " *")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1@s mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.phone, function (error) {
                      return _vm.errors.phone
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      class: { "uk-form-danger": _vm.errors.phone },
                      attrs: {
                        type: "text",
                        id: "phone",
                        name: "phone",
                        placeholder: _vm.translations.phone,
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.phone },
                        attrs: { for: "phone" },
                      },
                      [_vm._v(_vm._s(_vm.translations.phone) + " *")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1@s mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.email, function (error) {
                      return _vm.errors.email
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      class: { "uk-form-danger": _vm.errors.email },
                      attrs: {
                        type: "email",
                        id: "email",
                        name: "email",
                        placeholder: _vm.translations.email,
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.email },
                        attrs: { for: "email" },
                      },
                      [_vm._v(_vm._s(_vm.translations.email) + " *")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1 mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.subject, function (error) {
                      return _vm.errors.subject
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subject,
                          expression: "subject",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      class: { "uk-form-danger": _vm.errors.subject },
                      attrs: {
                        type: "text",
                        id: "subject",
                        name: "subject",
                        placeholder: _vm.translations.subject,
                      },
                      domProps: { value: _vm.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.subject = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.subject },
                        attrs: { for: "subject" },
                      },
                      [_vm._v(_vm._s(_vm.translations.subject) + " *")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-form-group uk-width-1-1 mt-2 d-flex flex-column flex-column-reverse",
                  },
                  [
                    _vm._l(_vm.errors.comments, function (error) {
                      return _vm.errors.comments
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.comments,
                          expression: "comments",
                        },
                      ],
                      staticClass: "uk-textarea",
                      class: { "uk-form-danger": _vm.errors.comments },
                      attrs: {
                        name: "comments",
                        id: "comments",
                        cols: "30",
                        rows: "4",
                      },
                      domProps: { value: _vm.comments },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.comments = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        class: { "uk-text-danger": _vm.errors.comments },
                        attrs: { for: "comments" },
                      },
                      [_vm._v(_vm._s(_vm.translations.comments))]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "uk-form-group uk-width-1-1 form-url",
                    attrs: { id: "url_wrapper" },
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "uk-form-label", attrs: { for: "url" } },
                      [_vm._v("Url *")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.url,
                          expression: "url",
                        },
                      ],
                      staticClass: "uk-input uk-form-control",
                      attrs: {
                        id: "url",
                        name: "url",
                        type: "text",
                        placeholder: "Url",
                      },
                      domProps: { value: _vm.url },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.url = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "uk-form-group mt-3" },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "uk-form-label uk-form-label-privacy custom-checkbox",
                        class: { "uk-form-danger": _vm.errors.privacy },
                        attrs: { for: "privacy" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.privacy,
                              expression: "privacy",
                            },
                          ],
                          staticClass: "uk-checkbox",
                          attrs: {
                            id: "privacy",
                            name: "privacy",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.privacy)
                              ? _vm._i(_vm.privacy, null) > -1
                              : _vm.privacy,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.privacy,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.privacy = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.privacy = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.privacy = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(_vm._s(_vm.translations.i_agree_with) + " "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.routes.privacy_page,
                              target: "_blank",
                              title: _vm.translations.privacy_policy,
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations.privacy_policy))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.errors.privacy, function (error) {
                      return _vm.errors.privacy
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-button-primary d-flex align-self-end me-5",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleFormSubmit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.submit))]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }