var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "my-1",
      attrs: { "uk-accordion": "", id: "accordion-" + _vm.category.id },
    },
    [
      _vm.category.sub_categories.length
        ? _c("li", { class: _vm.defaultChecked ? "uk-open" : "" }, [
            _c(
              "a",
              {
                staticClass: " text--normal justify-content-start",
                attrs: { id: "cat-" + _vm.category.id },
                on: { click: _vm.handleChange },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text text--normal text--small me-1 ",
                    class: _vm.defaultChecked ? "text--bold text-colored" : "",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.category.name) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "amount text--small text--normal",
                    class: _vm.defaultChecked ? "text--bold text-colored" : "",
                  },
                  [_vm._v("(" + _vm._s(_vm.category.products_count) + ")")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.defaultChecked,
                    expression: "defaultChecked",
                  },
                ],
                staticClass: " mt-1",
              },
              [
                _c("li", {}, [
                  _vm.category.sub_categories.length
                    ? _c(
                        "ul",
                        { staticClass: "sidebar__nav indent " },
                        _vm._l(
                          _vm.category.sub_categories,
                          function (subcategory) {
                            return _c("filter-sidebar-categories-item", {
                              key: subcategory.id,
                              attrs: {
                                category: subcategory,
                                level: _vm.level + 1,
                                "default-checked":
                                  _vm.activeCategories[subcategory.id] || false,
                                activeCategories: _vm.activeCategories,
                                subCategory: true,
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _c("li", [
            _vm.level > 0
              ? _c(
                  "label",
                  {
                    staticClass: "custom-custom-checkbox-label",
                    class: { " opacity-50": _vm.category.products_count == 0 },
                    attrs: { for: "cat-" + _vm.category.id },
                  },
                  [
                    _c("input", {
                      staticClass: "custom-custom-checkbox ",
                      class: {
                        " opacity-50": _vm.category.products_count == 0,
                      },
                      attrs: {
                        id: "cat-" + _vm.category.id,
                        name: "category",
                        type: "checkbox",
                      },
                      domProps: { checked: _vm.defaultChecked },
                      on: { change: _vm.handleChange },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "text text--normal text--small me-1" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.category.name) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "amount" }, [
                      _vm._v("(" + _vm._s(_vm.category.products_count) + ")"),
                    ]),
                  ]
                )
              : _c("label", [
                  _c(
                    "a",
                    {
                      staticClass: " text--normal justify-content-start",
                      attrs: { id: "cat-" + _vm.category.id },
                      on: { click: _vm.handleChange },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text me-1",
                          class: _vm.defaultChecked
                            ? "text--bold text-colored"
                            : "",
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.category.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "amount",
                          class: _vm.defaultChecked
                            ? "text--bold text-colored"
                            : "",
                        },
                        [
                          _vm._v(
                            "(" + _vm._s(_vm.category.products_count) + ")"
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }