<template>
  <div>
    <div class="d-flex flex-sm-row flex-column align-items-baseline">
      <h2 class="mb-0 mt-4 me-3">€ {{ activeProduct.use_price }}</h2>  <span class="uk-h5">{{translations.excl_vat}}</span>

    </div>
    <div class="product-specs mt-4 mb-4">
        <strong v-if="activeProduct.color">{{translations.color}}</strong>
        <p class="mt-0 mb-2">{{ activeProduct.color }}</p>
        <strong v-if="activeProduct.width && activeProduct.height">{{translations.size}}</strong>
        <p class="mt-0 mb-2">{{ activeProduct.width }}m x {{ activeProduct.height }}m</p>
        <strong v-if="activeProduct.surface">{{translations.surface}}</strong>
        <p class="mt-0 mb-2">{{ activeProduct.surface }}m²</p>
        <strong v-if="activeProduct.origin">{{translations.origin}}</strong>
        <p class="mt-0 ">{{ activeProduct.origin }}</p>
    </div>
    <div class="uk-width-1-1">
      <h2>{{translations.description}}</h2>
      <span v-html="activeProduct.description"></span>
    </div>

      <product-delivery-time
        v-if="activeProduct.delivery_time"
        :delivery-time="activeProduct.delivery_time"
        class="mt-3 mb-4"
      ></product-delivery-time>

    <section class="mb-4" v-if="sizes && sizes.length > 1">
      <div class="size-picker">
        <div class="d-flex align-items-baseline mb-2">
          <p class="my-0 me-4"><strong>{{ translations.chose_a }} {{ activeProduct.size_group.name }}</strong></p>
        </div>

        <div class="sizes">
          <product-configurator-size
            v-for="size in sizes"
            :size="size"
            :variation="getVariation(colorId, size.id)"
            :active-size="sizeId"
            :active-color="colorId"
            :key="size.id"
            @change="handleChange"
          ></product-configurator-size>
        </div>
      </div>
    </section>

    <section class="mb-4" v-if="colors && colors.length > 1">
      <div class="d-flex align-items-baseline mb-2">
        <p class="my-0 me-4"><strong>{{ translations.chose_a }} {{ translations.color }}</strong></p>
      </div>

      <div class="color-picker color-picker--wide">
        <product-configurator-color
          v-for="color in colors"
          :color="color"
          :variation="getVariation(color.id, sizeId)"
          :active-size="sizeId"
          :active-color="colorId"
          :key="color.id"
          @change="handleChange"
        ></product-configurator-color>
      </div>
    </section>

    <div class="cart-container mt-5">
      <div  v-if="activeProduct.stock > 1" class="uk-width-1-6@m uk-width-small@s uk-width-1-3 me-3">
        <form class="form-custom">
          <input type="number" name="amount" class="uk-input uk-text-center" v-model="quantity" min="1" :max="activeProduct.stock" />
        </form>
      </div>
			
			<div>
        <button class="uk-button uk-button-cart uk-width-1-1" @click.prevent="addToCart">
          <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.in_my_cart }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {EventBus} from "../EventBus";
  import ProductConfiguratorColor from "./ProductConfiguratorColor";
  import ProductConfiguratorSize from "./ProductConfiguratorSize";
  import collect from "collect.js";
  import ProductDeliveryTime from "./ProductDeliveryTime";

  export default {
    name: "ProductConfigurator",
    components: {ProductDeliveryTime, ProductConfiguratorSize, ProductConfiguratorColor},
    mounted() {
        if(this.allowHistory) {
            window.history.replaceState({product: this.product}, this.product.name, window.location.origin + this.product.slug);
        }

        window.onpopstate = e => {
            if (e.state?.product) {
                this.setActive(e.state.product);
            }
        }
    },
    data(){
      return {
        activeProduct: this.product,
        quantity: 1,
        isLoading: false,
        colorId: this.product?.color_id,
        sizeId: this.product?.size_id
      }
    },
    computed: {
      discountLabel: function() {
        let label = this.translations.you_save;

      },
      discountMinPiecesLabel: function() {

      }
    },
    methods: {
      handleChange(variation) {
        if (this.redirect) {
          window.location.href = variation.slug;
        } else {
          this.setActive(variation);
          this.addToHistory(variation);
        }
      },
      setActive(variation) {
        this.activeProduct = variation;
        this.colorId = variation?.color_id;
        this.sizeId = variation?.size_id;
        EventBus.$emit('PRODUCT_CHANGED', variation);
      },
      addToHistory(product) {
        if(this.allowHistory) {
            window.history.pushState({product: product}, product.name, window.location.origin + product.slug);
        }
      },
      getVariation(colorId, sizeId) {
        return collect(this.siblings)
          .where('color_id', colorId)
          .where('size_id', sizeId)
          .first()
      },
      addToCart(){
        this.isLoading = true;

        let quantity = this.quantity;
        if(quantity < 1 || isNaN(quantity))
        {
          quantity = 1;
        }

        window.axios.post(this.routes.addToCart, {product_id: this.activeProduct.id, quantity: quantity})
            .then(res => {
							if (res.data.error) {
								let message = {
									message: res.data.error.message,
									pos: "top-right",
									status: "danger",
									timeout: 3000,
								};

								EventBus.$emit('NOTIFY', message);
								this.isLoading = false;
								return;
							}
							
              EventBus.$emit('CART_UPDATE', res.data.cart);
              EventBus.$emit('PRODUCT_ADDED_MODAL', {product: this.activeProduct.add_product_modal, quantity: quantity});
              EventBus.$emit("CLOSE_PRODUCT_CARD_CONFIGURATOR");
              this.isLoading = false;
            })
            .catch(error => {
              let message = {
                message: '',
                pos: "top-right",
                status: "danger",
                timeout: 3000,
              };

              EventBus.$emit('NOTIFY', message);
              this.isLoading = false;
            });

      }
    },
    watch: {
      quantity: function(val, oldVal){
        if(val < 1 && val != "")
        {
          this.quantity = 1;
        }
      }
    },
    props: {
        product: {
            type: Object|Array,
            required: true
        },
        routes: {
            type: Object|Array,
            required: true
        },
        siblings: {
            type: Object|Array,
            required: false,
            default() { return [] }
        },
        colors: {
            type: Object|Array,
            required: false,
            default() { return [] }
        },
        sizes: {
            type: Object|Array,
            required: false,
            default() { return [] }
        },
        translations: {
            type: Object|Array,
            required: true
        },
        redirect: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowHistory: {
            type: Boolean,
            required: false,
            default: true,
        }
    }
  }
</script>

<style scoped>

</style>
