<template>
  <button @click.prevnet="clickable ? $emit('change', variation) : null" :disabled="true" class="size" :style="style" v-bind:class="{'uk-active': activeSize == this.size.id}">{{ value }}</button>
</template>

<script>
import {isEmpty} from "../functions";

export default {
  name: "ProductConfiguratorSize",
  data(){
    return {
      slug: this.variation?.slug ?? 'javascript:void(0)',
    }
  },
  computed: {
    value: function(){
      let value = this.size.value;

      if(this.size?.group?.unit_shorthand != null){
        value += " " + this.size.group.unit_shorthand;
      }else if(this.size?.group?.unit != null){
        value += " " + this.size.group.unit;
      }

      return value;
    },
    clickable: function(){
      return !isEmpty(this.variation);
    },
    style: function(){
      let style = '';
      if(!this.clickable){
        style += 'cursor: no-drop;';
      }

      return style;
    }
  },
  props:{
    size: {
      type: Object|Array,
      required: true
    },
    variation: {
      type: Object|null,
      default: null,
      required: true
    },
    activeSize: {
      type: Number,
      required: true
    },
    activeColor: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>

</style>