var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cart !== null
    ? _c("div", { staticClass: "cart", attrs: { id: "cart" } }, [
        _vm.cart.items.length
          ? _c(
              "div",
              [
                _vm.alertText
                  ? _c(
                      "div",
                      {
                        staticClass: "uk-alert-danger",
                        attrs: { "uk-alert": "" },
                      },
                      [
                        _c("a", {
                          staticClass: "uk-alert-close",
                          attrs: { "uk-close": "" },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.alertText))]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.cart.items, function (item) {
                  return _c("cart-overview-item", {
                    key: item.id,
                    attrs: {
                      translations: _vm.translations,
                      "init-item": item,
                      "show-vat": _vm.showVat,
                      route: item.product.slug || "#",
                      "highlight-stock": _vm.stockFailed,
                    },
                    on: {
                      "update-item": _vm.updateProduct,
                      "delete-item": _vm.deleteProduct,
                    },
                  })
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cart__footer row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-sm-12 mb-md-0 mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-sm-row flex-column justify-content-end align-items-sm-center mb-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-baseline my-sm-0 me-sm-4 mb-1",
                            },
                            [
                              _c("p", { staticClass: "my-0 me-2" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translations.shipping_pickup || ""
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-info-circle text--primary",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedDeliveryMethod,
                                  expression: "selectedDeliveryMethod",
                                },
                              ],
                              staticClass: "custom-select",
                              attrs: { name: "delivery" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedDeliveryMethod = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.updateDeliveryMethod,
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { selected: "", disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translations.choose_pickup ||
                                        "kies een optie"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.deliveryMethods,
                                function (name, method) {
                                  return _c(
                                    "option",
                                    { domProps: { value: method } },
                                    [_vm._v(_vm._s(name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.delivery_method
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-sm-end align-items-center mb-3",
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-exclamation-triangle me-3 uk-text-emphasis uk-text-danger",
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "my-0 uk-text-small uk-text-danger",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.errors.delivery_method[0]) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-sm-row flex-column justify-content-end align-items-sm-center mb-3",
                        },
                        [
                          _c("p", { staticClass: "my-sm-0 me-sm-4 mb-2" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.discount_giftcard)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "coupon" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.code,
                                  expression: "code",
                                },
                              ],
                              staticClass: "coupon__input",
                              attrs: {
                                type: "text",
                                placeholder: _vm.translations.enter_code || "",
                              },
                              domProps: { value: _vm.code },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.code = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "coupon__submit",
                                on: { click: _vm.applyCode },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-arrow-right",
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-sm-row flex-column justify-content-end align-items-sm-center mb-3",
                        },
                        [
                          _c("p", { staticClass: "my-sm-0 me-sm-4 mb-2" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.translations.pay_after)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "coupon" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.paymoment,
                                    expression: "paymoment",
                                  },
                                ],
                                staticClass: "custom-select",
                                attrs: { name: "paymoment" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.paymoment = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: 0 } }, [
                                  _vm._v(_vm._s(_vm.translations.no)),
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: 1 } }, [
                                  _vm._v(_vm._s(_vm.translations.yes)),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.code
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-sm-end align-items-center",
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-exclamation-triangle me-3 uk-text-emphasis",
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "my-0 uk-text-small" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.errors.code[0]) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-sm-end align-items-center uk-flex-column",
                        },
                        [
                          _vm.cart.discount_code
                            ? _c(
                                "div",
                                { staticClass: "uk-flex uk-flex-middle" },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "uk-display-inline-block my-0 me-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.translations.discount_code) +
                                          " '" +
                                          _vm._s(_vm.cart.discount_code.code) +
                                          "' (" +
                                          _vm._s(
                                            _vm.cart.discount_code.readable
                                          ) +
                                          ")."
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "cart__remove",
                                      on: { click: _vm.deleteDiscountCode },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-xmark",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            _vm.cart.applied_giftcards,
                            function (giftcard) {
                              return _c(
                                "div",
                                { staticClass: "uk-flex uk-flex-middle" },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "uk-display-inline-block my-0 me-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.translations.giftcard) +
                                          " '" +
                                          _vm._s(giftcard.blur) +
                                          "' (€ " +
                                          _vm._s(giftcard.formatted.saldo) +
                                          ")."
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "cart__remove",
                                      on: {
                                        click: function () {
                                          return _vm.deleteGiftCard(giftcard.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-xmark",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6 cart-footer-padding" }, [
                    _vm.showVat && !_vm.cart.is_intra_community
                      ? _c("div", { staticClass: "cart__totals" }, [
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(
                              _vm._s(_vm.translations.subtotal_excl_vat || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__price" }, [
                            _vm._v("€ " + _vm._s(_vm.cart.formatted.subtotal)),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(_vm._s(_vm.translations.vat || "")),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__price" }, [
                            _vm._v(
                              "€ " +
                                _vm._s(_vm.cart.formatted.vat_without_discount)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(
                              _vm._s(_vm.translations.subtotal_incl_vat || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__price" }, [
                            _vm._v(
                              "€ " +
                                _vm._s(_vm.cart.formatted.subtotal_incl_vat)
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.cart.discount > 0
                            ? _c("p", { staticClass: "cart__totals__label" }, [
                                _vm._v(_vm._s(_vm.translations.discount || "")),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.discount > 0
                            ? _c("p", { staticClass: "cart__totals__price" }, [
                                _vm._v(
                                  "- € " +
                                    _vm._s(_vm.cart.formatted.discount_incl_vat)
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.giftcard_reduction > 0
                            ? _c("p", { staticClass: "cart__totals__label" }, [
                                _vm._v(_vm._s(_vm.translations.giftcard || "")),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.giftcard_reduction > 0
                            ? _c("p", { staticClass: "cart__totals__price" }, [
                                _vm._v(
                                  "- € " +
                                    _vm._s(
                                      _vm.cart.formatted.giftcard_reduction
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(
                              _vm._s(_vm.translations.shipping_cost || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "cart__totals__price delivery-cost",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.cart.shipping_cost > 0
                                    ? "€ " + _vm.cart.formatted.shipping_cost
                                    : _vm.translations.free
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cart__totals__label total" },
                            [_vm._v(_vm._s(_vm.translations.total_incl_vat))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cart__totals__price total" },
                            [_vm._v("€ " + _vm._s(_vm.cart.formatted.total))]
                          ),
                        ])
                      : _c("div", { staticClass: "cart__totals" }, [
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(
                              _vm._s(_vm.translations.subtotal_incl_vat || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__price" }, [
                            _vm._v(
                              "€ " +
                                _vm._s(_vm.cart.formatted.subtotal_incl_vat)
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.cart.discount > 0
                            ? _c("p", { staticClass: "cart__totals__label" }, [
                                _vm._v(_vm._s(_vm.translations.discount || "")),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.discount > 0
                            ? _c("p", { staticClass: "cart__totals__price" }, [
                                _vm._v(
                                  "- € " +
                                    _vm._s(_vm.cart.formatted.discount_incl_vat)
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.giftcard_reduction > 0
                            ? _c("p", { staticClass: "cart__totals__label" }, [
                                _vm._v(_vm._s(_vm.translations.giftcard || "")),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cart.giftcard_reduction > 0
                            ? _c("p", { staticClass: "cart__totals__price" }, [
                                _vm._v(
                                  "- € " +
                                    _vm._s(
                                      _vm.cart.formatted.giftcard_reduction
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "cart__totals__label" }, [
                            _vm._v(
                              _vm._s(_vm.translations.shipping_cost || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "cart__totals__price delivery-cost",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.cart.shipping_cost > 0
                                    ? "€ " + _vm.cart.formatted.shipping_cost
                                    : _vm.translations.free
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cart__totals__label total" },
                            [_vm._v(_vm._s(_vm.translations.total_incl_vat))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cart__totals__price total" },
                            [_vm._v("€ " + _vm._s(_vm.cart.formatted.total))]
                          ),
                        ]),
                  ]),
                ]),
              ],
              2
            )
          : _c("div", { staticClass: "uk-text-center" }, [
              _c("h4", { staticClass: "uk-text-center my-2" }, [
                _vm._v(_vm._s(_vm.translations.empty)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-button-group uk-width-1-2 mx-auto uk-flex uk-flex-wrap uk-flex-around@l",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "uk-button uk-button-primary uk-width-1-1 uk-width-1-3@l uk-text-center mt-3",
                      attrs: { href: _vm.routes.shop_page },
                    },
                    [_vm._v(_vm._s(_vm.translations.all_products))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "uk-button uk-button-secondary uk-width-1-1 uk-width-1-3@l uk-text-center mt-3",
                      attrs: { href: _vm.routes.shop_home },
                    },
                    [_vm._v(_vm._s(_vm.translations.back_home))]
                  ),
                ]
              ),
            ]),
        _vm._v(" "),
        _vm.cart.items.length
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between justify-content-sm-end align-items-center",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "uk-button-back me-4",
                    attrs: { href: _vm.routes.continueShopping || "#" },
                  },
                  [
                    _c("i", { staticClass: "fa-solid fa-arrow-left me-2" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.translations.continue_shopping) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.cart.items.length
                  ? _c(
                      "button",
                      {
                        staticClass: "uk-button uk-button-primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.validateCart.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.validationIsLoading
                          ? _c("div", { staticClass: "loader" }, [
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-circle-notch fa-spin me-2",
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.translations.continue_order) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ])
    : _c("div", [_c("div", { staticClass: "uk-spinner uk-align-center" })])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }