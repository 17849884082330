var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "mobile-menu__content mt-4" },
    _vm._l(_vm.otherPages, function (page) {
      return _c("li", [
        _c("a", { attrs: { href: page.url, title: page.title } }, [
          _c("section", { staticClass: "d-flex align-items-center" }, [
            _c("span", { domProps: { innerHTML: _vm._s(page.title) } }),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }