var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-sm-row flex-column align-items-baseline" },
        [
          _c("h2", { staticClass: "mb-0 mt-4 me-3" }, [
            _vm._v("€ " + _vm._s(_vm.activeProduct.use_price)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "uk-h5" }, [
            _vm._v(_vm._s(_vm.translations.excl_vat)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product-specs mt-4 mb-4" }, [
        _vm.activeProduct.color
          ? _c("strong", [_vm._v(_vm._s(_vm.translations.color))])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "mt-0 mb-2" }, [
          _vm._v(_vm._s(_vm.activeProduct.color)),
        ]),
        _vm._v(" "),
        _vm.activeProduct.width && _vm.activeProduct.height
          ? _c("strong", [_vm._v(_vm._s(_vm.translations.size))])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "mt-0 mb-2" }, [
          _vm._v(
            _vm._s(_vm.activeProduct.width) +
              "m x " +
              _vm._s(_vm.activeProduct.height) +
              "m"
          ),
        ]),
        _vm._v(" "),
        _vm.activeProduct.surface
          ? _c("strong", [_vm._v(_vm._s(_vm.translations.surface))])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "mt-0 mb-2" }, [
          _vm._v(_vm._s(_vm.activeProduct.surface) + "m²"),
        ]),
        _vm._v(" "),
        _vm.activeProduct.origin
          ? _c("strong", [_vm._v(_vm._s(_vm.translations.origin))])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "mt-0 " }, [
          _vm._v(_vm._s(_vm.activeProduct.origin)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _c("h2", [_vm._v(_vm._s(_vm.translations.description))]),
        _vm._v(" "),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.activeProduct.description) },
        }),
      ]),
      _vm._v(" "),
      _vm.activeProduct.delivery_time
        ? _c("product-delivery-time", {
            staticClass: "mt-3 mb-4",
            attrs: { "delivery-time": _vm.activeProduct.delivery_time },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.sizes && _vm.sizes.length > 1
        ? _c("section", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "size-picker" }, [
              _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
                _c("p", { staticClass: "my-0 me-4" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.translations.chose_a) +
                        " " +
                        _vm._s(_vm.activeProduct.size_group.name)
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sizes" },
                _vm._l(_vm.sizes, function (size) {
                  return _c("product-configurator-size", {
                    key: size.id,
                    attrs: {
                      size: size,
                      variation: _vm.getVariation(_vm.colorId, size.id),
                      "active-size": _vm.sizeId,
                      "active-color": _vm.colorId,
                    },
                    on: { change: _vm.handleChange },
                  })
                }),
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.colors && _vm.colors.length > 1
        ? _c("section", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
              _c("p", { staticClass: "my-0 me-4" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.translations.chose_a) +
                      " " +
                      _vm._s(_vm.translations.color)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "color-picker color-picker--wide" },
              _vm._l(_vm.colors, function (color) {
                return _c("product-configurator-color", {
                  key: color.id,
                  attrs: {
                    color: color,
                    variation: _vm.getVariation(color.id, _vm.sizeId),
                    "active-size": _vm.sizeId,
                    "active-color": _vm.colorId,
                  },
                  on: { change: _vm.handleChange },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "cart-container mt-5" }, [
        _vm.activeProduct.stock > 1
          ? _c(
              "div",
              {
                staticClass:
                  "uk-width-1-6@m uk-width-small@s uk-width-1-3 me-3",
              },
              [
                _c("form", { staticClass: "form-custom" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quantity,
                        expression: "quantity",
                      },
                    ],
                    staticClass: "uk-input uk-text-center",
                    attrs: {
                      type: "number",
                      name: "amount",
                      min: "1",
                      max: _vm.activeProduct.stock,
                    },
                    domProps: { value: _vm.quantity },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.quantity = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-cart uk-width-1-1",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addToCart.apply(null, arguments)
                },
              },
            },
            [
              _vm.isLoading
                ? _c("div", { staticClass: "loader" }, [
                    _c("i", {
                      staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                    }),
                  ])
                : _vm._e(),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.translations.in_my_cart) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }