var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "uk-child-width-1-2@m uk-grid-medium mb-4",
        attrs: { "uk-grid": "masonry: true" },
      },
      _vm._l(_vm.localAddresses, function (address) {
        return _c("user-adress-card", {
          key: address.id,
          attrs: {
            "init-address": address,
            "default-delivery": _vm.localDefaultDelivery,
            "default-billing": _vm.localDefaultBilling,
            countries: _vm.countries,
            routes: _vm.routes,
            translations: _vm.translations,
          },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "uk-button uk-button-primary",
        attrs: { href: "javascript:void(0)" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.handleStartEdit.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-plus me-1" }),
        _vm._v(_vm._s(_vm.translations.new_address)),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-modal-container uk-flex-top",
        attrs: {
          id: "new-address-modal",
          "uk-modal": "esc-close: false; bg-close: false; keyboard: false;",
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "uk-modal-dialog uk-modal-body uk-margin-auto-vertical",
          },
          [
            _c(
              "form",
              {
                staticClass: "form-custom form-custom-white",
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleUpdate.apply(null, arguments)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "uk-grid-small", attrs: { "uk-grid": "" } },
                  [
                    _c("div", { staticClass: "uk-width-1-2@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "streetname" },
                        },
                        [_vm._v(_vm._s(_vm.translations.streetname))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.street,
                              expression: "newAddress.street",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "streetname",
                            type: "text",
                            placeholder: _vm.translations.streetname,
                          },
                          domProps: { value: _vm.newAddress.street },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "street",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.street
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.street,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "number" },
                        },
                        [_vm._v(_vm._s(_vm.translations.housenr))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.number,
                              expression: "newAddress.number",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "number",
                            type: "text",
                            placeholder: _vm.translations.housenr,
                          },
                          domProps: { value: _vm.newAddress.number },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "number",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.number
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.number,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "number_addon" },
                        },
                        [_vm._v(_vm._s(_vm.translations.number_addon_short))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.number_addon,
                              expression: "newAddress.number_addon",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "number_addon",
                            type: "text",
                            placeholder: _vm.translations.number_addon_short,
                          },
                          domProps: { value: _vm.newAddress.number_addon },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "number_addon",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.number_addon
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.number_addon,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-6@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "busnumber" },
                        },
                        [_vm._v(_vm._s(_vm.translations.busnr))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.busnumber,
                              expression: "newAddress.busnumber",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "busnumber",
                            type: "text",
                            placeholder: "Busnr.",
                          },
                          domProps: { value: _vm.newAddress.busnumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "busnumber",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.busnr
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.busnr,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-4@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "postalcode" },
                        },
                        [_vm._v(_vm._s(_vm.translations.postalcode))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.postalcode,
                              expression: "newAddress.postalcode",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "postalcode",
                            type: "text",
                            placeholder: _vm.translations.postalcode,
                          },
                          domProps: { value: _vm.newAddress.postalcode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "postalcode",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.postalcode
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.postalcode,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-2@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "commune" },
                        },
                        [_vm._v(_vm._s(_vm.translations.commune))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newAddress.commune,
                              expression: "newAddress.commune",
                            },
                          ],
                          staticClass: "uk-input",
                          attrs: {
                            id: "commune",
                            type: "text",
                            placeholder: _vm.translations.commune,
                          },
                          domProps: { value: _vm.newAddress.commune },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newAddress,
                                "commune",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.newAddressErrors.commune
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.commune,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-4@s" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "country" },
                        },
                        [_vm._v(_vm._s(_vm.translations.country))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newAddress.countryId,
                                expression: "newAddress.countryId",
                              },
                            ],
                            staticClass: "custom-select uk-width-1-1",
                            attrs: { name: "company-type", id: "country" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.newAddress,
                                  "countryId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.countries, function (country) {
                            return _c(
                              "option",
                              { domProps: { value: country.id } },
                              [_vm._v(_vm._s(country.name))]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.newAddressErrors.country_id
                          ? _c(
                              "div",
                              { staticClass: "input-errors" },
                              _vm._l(
                                _vm.newAddressErrors.country_id,
                                function (error) {
                                  return _c("p", [_vm._v(_vm._s(error))])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-1 mb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-margin uk-grid-small uk-child-width-auto uk-grid",
                        },
                        [
                          _c("p", [
                            _c("span", { staticClass: "uk-text-bold" }, [
                              _vm._v(
                                _vm._s(_vm.translations.default_address) + "s"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "custom-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newAddress.defaultDelivery,
                                  expression: "newAddress.defaultDelivery",
                                },
                              ],
                              staticClass: "uk-checkbox me-2 mt-1",
                              attrs: {
                                type: "checkbox",
                                name: "default_delivery",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.newAddress.defaultDelivery
                                )
                                  ? _vm._i(
                                      _vm.newAddress.defaultDelivery,
                                      null
                                    ) > -1
                                  : _vm.newAddress.defaultDelivery,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.newAddress.defaultDelivery,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.newAddress,
                                          "defaultDelivery",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.newAddress,
                                          "defaultDelivery",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.newAddress,
                                      "defaultDelivery",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "my-0" }, [
                              _vm._v(_vm._s(_vm.translations.default_delivery)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.newAddressErrors.default_delivery
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.newAddressErrors.default_delivery,
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("label", { staticClass: "custom-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newAddress.defaultBilling,
                                  expression: "newAddress.defaultBilling",
                                },
                              ],
                              staticClass: "uk-checkbox me-2 mt-1",
                              attrs: {
                                type: "checkbox",
                                name: "default_billing",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.newAddress.defaultBilling
                                )
                                  ? _vm._i(
                                      _vm.newAddress.defaultBilling,
                                      null
                                    ) > -1
                                  : _vm.newAddress.defaultBilling,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.newAddress.defaultBilling,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.newAddress,
                                          "defaultBilling",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.newAddress,
                                          "defaultBilling",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.newAddress,
                                      "defaultBilling",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "my-0" }, [
                              _vm._v(_vm._s(_vm.translations.default_billing)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.newAddressErrors.default_billing
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.newAddressErrors.default_billing,
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-1" }, [
                      _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c(
                          "button",
                          {
                            staticClass: "uk-button uk-button-secondary me-2",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancelUpdate.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations.cancel))]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "uk-button uk-button-primary",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleUpdate.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm.newIsLoading
                              ? _c("div", { staticClass: "loader" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa-solid fa-circle-notch fa-spin me-2",
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.translations.save) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }