var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mobile-menu",
      attrs: {
        id: "offcanvas-mobile-filters",
        "uk-offcanvas": "flip: true; overlay: true",
      },
    },
    [
      _c("div", { staticClass: "uk-offcanvas-bar" }, [
        _c("div", { staticClass: "mobile-menu__header" }, [
          _c("img", {
            staticClass: "lazyload",
            attrs: {
              "data-src": _vm.logoUrl,
              width: "20",
              alt: "Logo",
              draggable: "false",
            },
          }),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("form", { staticClass: "form-custom" }, [
          _c(
            "ul",
            {
              staticClass: "mobile-menu__accordion",
              attrs: { "uk-accordion": "" },
            },
            [
              _vm.categories.length > 0
                ? _c("filter-sidebar-categories", {
                    attrs: {
                      categories: _vm.categories,
                      activeCategories: _vm.activeCategories,
                      category: _vm.category,
                      "uk-open": true,
                      translations: _vm.translations,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.colors.length > 0
                ? _c("filter-sidebar-colors", {
                    attrs: {
                      colors: _vm.colors,
                      translations: _vm.translations,
                      "active-colors": _vm.activeColors,
                      "uk-open": false,
                    },
                    model: {
                      value: _vm.activeColors,
                      callback: function ($$v) {
                        _vm.activeColors = $$v
                      },
                      expression: "activeColors",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.brands.length > 0
                ? _c("filter-sidebar-brands", {
                    attrs: {
                      brands: _vm.brands,
                      translations: _vm.translations,
                      "active-brands": _vm.activeBrands,
                      "uk-open": false,
                    },
                    model: {
                      value: _vm.activeBrands,
                      callback: function ($$v) {
                        _vm.activeBrands = $$v
                      },
                      expression: "activeBrands",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.sizes, function (sizegroup) {
                return _c("filter-sidebar-sizes-group", {
                  key: sizegroup.id,
                  attrs: {
                    sizegroup: sizegroup,
                    "active-sizes": _vm.activeSizes,
                    "uk-open": false,
                  },
                  model: {
                    value: _vm.activeSizes,
                    callback: function ($$v) {
                      _vm.activeSizes = $$v
                    },
                    expression: "activeSizes",
                  },
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.dynamicfilters, function (filter) {
                return _c("filter-sidebar-dynamic-filters", {
                  key: filter.id,
                  attrs: {
                    filter: filter,
                    "active-filters": _vm.activeDynamicalFilters,
                    "uk-open": false,
                  },
                  model: {
                    value: _vm.activeDynamicalFilters,
                    callback: function ($$v) {
                      _vm.activeDynamicalFilters = $$v
                    },
                    expression: "activeDynamicalFilters",
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobile-menu__apply" }, [
          _c(
            "button",
            { staticClass: "uk-button uk-button-primary uk-width-1-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translations.apply_filters) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "uk-offcanvas-close", attrs: { type: "button" } },
      [_c("i", { staticClass: "fa-solid fa-xmark" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }