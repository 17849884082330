<template>
	<ul uk-accordion class="my-1" :id="`accordion-${category.id}`">
	    <li v-if="category.sub_categories.length" :class="defaultChecked ? 'uk-open' : ''">
	        <a class=" text--normal justify-content-start"  :id="`cat-${category.id}`" @click="handleChange" >
						<span class="text text--normal text--small me-1 " :class="defaultChecked ? 'text--bold text-colored' : ''">
							{{ category.name }}
						</span>				            
						<span class="amount text--small text--normal" :class="defaultChecked ? 'text--bold text-colored' : ''">({{ category.products_count }})</span>

			</a>
	        <div v-show="defaultChecked" class=" mt-1">
				<li class="">
					<ul class="sidebar__nav indent " v-if="category.sub_categories.length">
						<filter-sidebar-categories-item
							v-for="subcategory in category.sub_categories"
							:category="subcategory"
							:key="subcategory.id"
							:level="level + 1"
							:default-checked="activeCategories[subcategory.id] || false"
							:activeCategories="activeCategories"
							:subCategory="true"

						></filter-sidebar-categories-item>
					</ul>
    			</li>
			</div>
	    </li>
		<li v-else>
			<label v-if="level>0" :for="`cat-${category.id}`" class="custom-custom-checkbox-label" :class="{ ' opacity-50' : category.products_count == 0 }" >
				<input @change="handleChange" :id="`cat-${category.id}`" class="custom-custom-checkbox "  :class="{ ' opacity-50' : category.products_count == 0 }" :checked="defaultChecked" name="category" type="checkbox">
				<span class="checkmark"></span>
				<span class="text text--normal text--small me-1">
					{{ category.name }}
				</span>				            
				<span class="amount">({{ category.products_count }})</span>
			</label>
			<label v-else>
				<a class=" text--normal justify-content-start"  :id="`cat-${category.id}`" @click="handleChange" >

					<span class="text me-1" :class="defaultChecked ? 'text--bold text-colored' : ''">
						{{ category.name }}
					</span>				            
					<span class="amount" :class="defaultChecked ? 'text--bold text-colored' : ''">({{ category.products_count }})</span>
				</a>
			</label>
		</li>
	</ul>
    
</template>

<script>
import { EventBus } from '../EventBus';

export default {
    name: "FilterSidebarCategoriesItem",
    mounted() {
    },
    data() {
        return {
			checked: this.defaultChecked,
			localActiveCategories: this.activeCategories || []

        }
    },
    methods: {
        handleChange() {
            EventBus.$emit('CATEGORIES_CHANGED', {level: this.level, id:this.category.id});
        }
    },
	computed: {
      disabled: function(){
        if(this.category.products_count == 0) {
          return true;
        }
        return false;
      },

    },
    props: {
        category: {
            type: Object|Array,
            required: true
        },
		level: {
			type: Number,
			required: false,
			default: 0
		},
		defaultChecked: {
            type: Boolean,
            default: false
        },
		activeCategories: {
            type: Object|Array,
            required: true
        },
		subCategory: {
            type: Boolean,
			required: false,
            default: false
        },
    }
}
</script>

<style scoped>
    .indent {
		margin-left:16px;
	}
    .indent-line {
		border-left: 1px solid #e8e8e8;
	}
	.opacity-50 {
		opacity: 0.5;
	}

	.custom-custom-checkbox-label:not(.disabled):hover span {
		color: #b15c40;
	}

	.custom-custom-checkbox:checked + .custom-custom-checkbox-label span {
		font-weight: bold;
		color: #b15c40;
	}
</style>
