var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-custom form-custom-white uk-grid-small",
      attrs: { "uk-grid": "" },
    },
    [
      _c("h3", { staticClass: "uk-text-primary" }, [
        _vm._v(_vm._s(_vm.translations.personal_data)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2" }),
      _vm._v(" "),
      _vm.accountType === "business"
        ? _c("div", { staticClass: "uk-width-1-2@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companyname" } },
              [_vm._v(_vm._s(_vm.translations.company.companyname) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyname,
                    expression: "companyname",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companyname",
                  type: "text",
                  placeholder: _vm.translations.company.companyname,
                },
                domProps: { value: _vm.companyname },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyname = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.companyname
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.errors.companyname, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.accountType === "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companytype" } },
              [_vm._v(_vm._s(_vm.translations.company.type) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyType,
                      expression: "companyType",
                    },
                  ],
                  staticClass: "custom-select uk-width-1-1",
                  attrs: { name: "company-type", id: "companytype" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.companyType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v(_vm._s(_vm.translations.company.type)),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.legalForms, function (legalForm) {
                    return _c("option", { domProps: { value: legalForm } }, [
                      _vm._v(_vm._s(legalForm)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.company_type
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.errors.company_type, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.accountType === "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companyvat" } },
              [_vm._v(_vm._s(_vm.translations.company.vat) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyVat,
                    expression: "companyVat",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companyvat",
                  type: "text",
                  placeholder: _vm.translations.company.vat,
                },
                domProps: { value: _vm.companyVat },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyVat = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.company_vat
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.errors.company_vat, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mb-3 mt-4" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.salutation.self)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "salutation", value: "male" },
                domProps: { checked: _vm._q(_vm.salutation, "male") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "male"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.male)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "salutation", value: "female" },
                domProps: { checked: _vm._q(_vm.salutation, "female") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "female"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.female)),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.salutation
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.errors.salutation, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "firstname" } },
          [_vm._v(_vm._s(_vm.translations.firstname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.firstname,
                expression: "firstname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "firstname",
              type: "text",
              placeholder: _vm.translations.firstname,
            },
            domProps: { value: _vm.firstname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.firstname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.firstname
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.firstname, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "lastname" } },
          [_vm._v(_vm._s(_vm.translations.lastname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.lastname,
                expression: "lastname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "lastname",
              type: "text",
              placeholder: _vm.translations.lastname,
            },
            domProps: { value: _vm.lastname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.lastname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.lastname
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.lastname, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _vm.userPhoneRequired
          ? _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell) + " *")]
            )
          : _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell))]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "phone",
              type: "text",
              placeholder: _vm.translations.tel_or_cell,
            },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.phone
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.phone, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleSavePersonalData.apply(null, arguments)
              },
            },
          },
          [
            _vm.personalDataIsLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.update_personal_data) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-5" }, [
        _c("h3", { staticClass: "uk-text-primary" }, [
          _vm._v(_vm._s(_vm.translations.login_data)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "uk-text-small" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.translations.login_data_expl) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.translations.email) + " *"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "email",
              type: "email",
              placeholder: _vm.translations.email,
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.email
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.email, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "password" } },
          [_vm._v(_vm._s(_vm.translations.password))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "password",
              type: "password",
              placeholder: _vm.translations.password,
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.password
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.password, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "confirm_password" } },
          [_vm._v(_vm._s(_vm.translations.password_confirm))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmPassword,
                expression: "confirmPassword",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "confirm_password",
              type: "password",
              placeholder: _vm.translations.password_confirm,
            },
            domProps: { value: _vm.confirmPassword },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.confirmPassword = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.confirm_password
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.confirm_password, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleSaveLoginData.apply(null, arguments)
              },
            },
          },
          [
            _vm.loginDataIsLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v(
              "\n      " + _vm._s(_vm.translations.update_login_data) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }