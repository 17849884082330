var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "size",
      class: { "uk-active": _vm.activeSize == this.size.id },
      style: _vm.style,
      attrs: { disabled: true },
      on: {
        click: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "prevnet", undefined, $event.key, undefined)
          ) {
            return null
          }
          _vm.clickable ? _vm.$emit("change", _vm.variation) : null
        },
      },
    },
    [_vm._v(_vm._s(_vm.value))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }