<template>
  <form class="form-custom mt-4 d-flex flex-column">
    <div class="mb-4">
      <input type="text" v-model="username" name="username" class="uk-input" :placeholder="translations.email">
      <div class="input-errors" v-if="usernameErrors.length > 0">
        <p v-for="error in usernameErrors">{{ error }}</p>
      </div>
    </div>
    <div class="mb-3">
      <input type="password" v-model="password" name="password" class="uk-input" :placeholder="translations.password">
      <div class="input-errors" v-if="passwordErrors.length > 0">
        <p v-for="error in passwordErrors">{{ error }}</p>
      </div>
    </div>

    <!-- <div class="uk-width-1-1">
      <label class="custom-checkbox">
        <input v-model="loginPersist" class="uk-checkbox me-2 mt-1" type="checkbox">
        <p class="my-0">{{ translations.login_persist }}</p>
      </label>
    </div> -->
      <div class="uk-width-1-1 d-flex justify-content-end">
        <p class="my-0 text--small">
          <a v-bind:href="routes.register" class="password-link">{{ translations.account_request }}</a>
        </p> 
        <p class="mx-2 mt-0 mb-1 password-stripe">/</p>
        <p class="my-0 text--small">
          <a v-bind:href="routes.password_forgotten" class="password-link">{{ translations.forgot_password }}</a>
        </p>
      </div>

    <div class="uk-width-1-1 mt-4 ">
      <div class="d-flex flex-sm-row flex-column align-items-sm-center">
        <button type="submit" @click.prevent="handleLogin" class="uk-button uk-button-primary mb-sm-0 mb-4 uk-width-1-1">
          <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.login }}
        </button>
        
      </div>
    </div>
  </form>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "LoginForm",
    mounted() {
    },
    data(){
      return {
        username: '',
        password: '',
        loginPersist: false,
        usernameErrors: {},
        passwordErrors: {},
        isLoading: false,
      }
    },
    methods: {
      handleLogin(){
        this.isLoading = true;
        window.axios.post(this.routes.login, {username: this.username, password: this.password, persist: this.loginPersist})
          .then(res => {
            this.usernameErrors = {};
            this.passwordErrors = {};

            let resData = res.data;
            if(resData.success)
            {
              window.location.href = this.routes.products;
            }
            else
            {
              EventBus.$emit('NOTIFY', {message: resData.message, status: 'danger', position: 'top-right', 'timeout': 3000});
              this.isLoading = false;
            }
          })
          .catch(err => {
            this.usernameErrors = {};
            this.passwordErrors = {};

            this.usernameErrors = err?.response?.data?.username ?? {};
            this.passwordErrors = err?.response?.data?.password ?? {};

            EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});
            this.isLoading = false;
          });
      },
    },
    props: {
      translations: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>