<template>
    <li v-bind:class="{'uk-open': ukOpen}">
        <a class="uk-accordion-title" href="#">
            <span>{{ categoryName }}</span>
            <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
            <ul class="sidebar__nav">
                <filter-sidebar-categories-item
                    v-for="category in categories"
                   	:category="category"
                    :key="category.id"
					:default-checked="activeCategories[category.id] || false"
                    :active-categories="activeCategories"
                >
                </filter-sidebar-categories-item>
            </ul>
        </div>
    </li>
</template>

<script>
export default {
    name: "FilterSidebarCategories",
    mounted() {
    },
    data() {
        return {
            localActiveCategories: this.activeCategories || []
        }
    },
    computed: {
        categoryName() {
            return this?.category?.name ?? this.translations.categories;
        },
    },
    methods: {
        handleChange() {
            this.$emit('input', this.localActiveCategories);
        }
    },
    props: {
        categories: {
            type: Object|Array,
            required: true
        },
		activeCategories: {
            type: Object|Array,
            required: true
        },
        ukOpen: {
          type: Boolean,
          required: false,
          default: true
        },
        translations: {
            type: Object|Array,
            required: true,
        }
    }
}
</script>

<style scoped>
</style>
