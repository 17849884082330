<template>
  <form class="form-custom form-custom-white uk-grid-small" uk-grid>
    <h3 class="uk-text-primary">{{ translations.personal_data }}</h3>

    <div class="uk-width-1-2"></div>

    <div class="uk-width-1-2@s" v-if="accountType === 'business'">
      <label class="uk-form-label" for="companyname">{{ translations.company.companyname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyname" type="text" :placeholder="translations.company.companyname" v-model="companyname">

        <div class="input-errors" v-if="errors.companyname">
          <p v-for="error in errors.companyname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-4@s" v-if="accountType === 'business'">
      <label class="uk-form-label" for="companytype">{{ translations.company.type }} *</label>
      <div class="uk-form-controls">
        <select name="company-type" id="companytype" class="custom-select uk-width-1-1" v-model="companyType">
          <option value="" selected>{{translations.company.type}}</option>
          <option v-for="legalForm in legalForms" :value="legalForm">{{ legalForm }}</option>
        </select>

        <div class="input-errors" v-if="errors.company_type">
          <p v-for="error in errors.company_type">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-4@s" v-if="accountType === 'business'">
      <label class="uk-form-label" for="companyvat">{{ translations.company.vat }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyvat" type="text" :placeholder="translations.company.vat" v-model="companyVat">

        <div class="input-errors" v-if="errors.company_vat">
          <p v-for="error in errors.company_vat">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mb-3 mt-4">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.salutation.self }}</span></p>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="salutation" value="male" v-model="salutation">
          <p class="my-0">{{ translations.salutation.male }}</p>
        </label>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="salutation" value="female" v-model="salutation">
          <p class="my-0">{{ translations.salutation.female }}</p>
        </label>

        <div class="input-errors" v-if="errors.salutation">
          <p v-for="error in errors.salutation">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="firstname">{{ translations.firstname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="firstname" type="text" :placeholder="translations.firstname" v-model="firstname">
        <div class="input-errors" v-if="errors.firstname">
          <p v-for="error in errors.firstname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="lastname">{{ translations.lastname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="lastname" type="text" :placeholder="translations.lastname" v-model="lastname">
        <div class="input-errors" v-if="errors.lastname">
          <p v-for="error in errors.lastname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1">
      <label class="uk-form-label" for="phone" v-if="userPhoneRequired">{{ translations.tel_or_cell }} *</label>
      <label class="uk-form-label" for="phone" v-else>{{ translations.tel_or_cell }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="phone" type="text" :placeholder="translations.tel_or_cell" v-model="phone">
        <div class="input-errors" v-if="errors.phone">
          <p v-for="error in errors.phone">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-4">
      <button type="submit" @click.prevent="handleSavePersonalData" class="uk-button uk-button-primary">
        <div class="loader" v-if="personalDataIsLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
        {{ translations.update_personal_data }}
      </button>
    </div>

    <div class="uk-width-1-1 mt-5">
      <h3 class="uk-text-primary">{{ translations.login_data }}</h3>
      <p class="uk-text-small">
        {{ translations.login_data_expl }}
      </p>
    </div>

    <div class="uk-width-1-3@s">
      <label class="uk-form-label" for="email">{{ translations.email }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="email" type="email" :placeholder="translations.email" v-model="email">
        <div class="input-errors" v-if="errors.email">
          <p v-for="error in errors.email">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-3@s">
      <label class="uk-form-label" for="password">{{ translations.password }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="password" type="password" :placeholder="translations.password" v-model="password">
        <div class="input-errors" v-if="errors.password">
          <p v-for="error in errors.password">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-3@s">
      <label class="uk-form-label" for="confirm_password">{{ translations.password_confirm }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="confirm_password" type="password" :placeholder="translations.password_confirm" v-model="confirmPassword">
        <div class="input-errors" v-if="errors.confirm_password">
          <p v-for="error in errors.confirm_password">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-4">
      <button type="submit" @click.prevent="handleSaveLoginData" class="uk-button uk-button-primary">
        <div class="loader" v-if="loginDataIsLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
        {{ translations.update_login_data }}
      </button>
    </div>
  </form>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "UserDataForm",
    mounted(){

    },
    data (){
        return{
          errors: {},
          accountType: 'business',
          companyname: this.user?.companyname ?? "",
          companyType: this.user?.company_type ?? "",
          companyVat: this.user?.company_vat ?? "",
          salutation: this.user?.salutation ?? "",
          firstname: this.user?.firstname ?? "",
          lastname: this.user?.lastname ?? "",
          phone: this.user?.phone ?? "",
          email: this.user?.email ?? "",
          password: '',
          confirmPassword: '',
          personalDataIsLoading: false,
          loginDataIsLoading: false,
        }
    },
    methods: {
      handleSavePersonalData(){
        this.personalDataIsLoading = true;

        let data = {
          account_type: this.accountType,
          companyname: this.companyname,
          company_type: this.companyType,
          company_vat: this.companyVat,
          salutation: this.salutation,
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone
        };

        window.axios.post(this.routes.personaldata, data)
          .then(res => {
              this.errors = {};

              let resData = res.data;
              if(resData.message) {
                  EventBus.$emit("NOTIFY", resData.message);
              }
              if(resData.errors) {
                  this.errors = resData.errors;
              }

              this.personalDataIsLoading = false;
          })
          .catch(err => {
              this.errors = err?.response?.data ?? {};

              EventBus.$emit("NOTIFY", {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', timeout: 3000});

              this.personalDataIsLoading = false;
          });
      },
      handleSaveLoginData(){
        this.loginDataIsLoading = true;

        let data = {
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword
        };

        window.axios.post(this.routes.logindata, data)
          .then(res => {
            this.errors = {};

            let resData = res.data;

            if(resData.messages)
            {
              resData.messages.forEach(message => {
                EventBus.$emit('NOTIFY', message)
              });
            }

            if(resData.errors)
            {
              this.errors = resData.errors;
            }

            this.loginDataIsLoading = false;
          })
          .catch(err => {
            this.errors = err?.response?.data;

            EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});

            this.loginDataIsLoading = false;
          });
      }
    },
    props: {
      translations: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      },
      user: {
        type: Object|Array,
        required: true
      },
      userPhoneRequired: {
        type: Boolean,
        required: false,
        default: true
      },
      legalForms: {
        type: Object|Array,
        required: false,
        default() { return []; }
      }
    }
  }
</script>

<style scoped>

</style>