import UIkit from 'uikit';
import Cookies from 'js-cookie';
import * as functions from './functions.js';

require('picturefill')
require('lazysizes')
require('lazysizes/plugins/bgset/ls.bgset')
window.lazySizesConfig = { loadMode: 3 }

//Detect if Facebook apps (Instagram, FB, ...) in-app browser agents are used, then returns true.

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Locale'] = window.locale ?? 'nl';


/*=============================================
=                  LOAD VUE                   =
=============================================*/

window.Vue = require('vue');
window.Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

const files = require.context('./Components', true, /\.vue$/i);

files
  .keys()
  .map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

const app = new Vue({
  el: '#app',
});

functions.docReady(function () {
  // ==========[ COOKIES ]==========
  // check if cookie modal exists (found in /site/templates/includes/cookie.php)
  if (functions.domElementExists("#cookie-modal")) {
    // if cookie preferenes aren't saved, show cookie modal after 2 seconds
    const gotConsent = Cookies.get('cookie_pref_saved');
    if (!gotConsent || gotConsent === undefined) {
      document.querySelector("#cookie-modal").style.display = 'flex';
    }

    // saving cookies button clicked, save user preferecnes
    functions.createEvent("#submit_cookie", "click", function () {
      functions.cookieHandler();
      document.querySelector("#cookie-modal").style.display = 'none';
    });

    // accepting all cookies, save user preferences
    functions.createEvent("#approve_all", "click", function () {
      document.querySelectorAll('#cookie_form input[type=checkbox]').forEach(cookieOption => {
        cookieOption.checked = true;
      });
      setTimeout(() => {
        functions.cookieHandler();
      }, 300);
    });

    // handle show cookie settings
    functions.createEvent("#open_cookie_preferences", "click", function () {
      document.querySelector("#cookie_form").style.display = 'flex';
    });

    functions.createEvent("#show_cookie_settings", "click", function () {
      document.querySelector("#cookie-modal").style.display = 'flex';
    });
  }


  // ==========[ POPUP ]==========
  const popupDismissed = sessionStorage.getItem('popup_dismissed');
  if (functions.domElementExists("#popup-modal") && (!popupDismissed || popupDismissed === undefined)) {
    UIkit.modal(document.querySelector("#popup-modal")).show();
  };
  // on closing popup, save: do not show again for current browser (open tab) session
  UIkit.util.on('#popup-modal', 'hide', function () {
    sessionStorage.setItem("popup_dismissed", 1);
  });
  // on clicking popup link, save: do not show again for current browser (open tab) session
  functions.createEvent(".popup--links a", "click", function () {
    sessionStorage.setItem("popup_dismissed", 1);
  });

  


  // ==========[ DEFAULT FORM HANDLER ]==========
  functions.createEvent('.submit_form', 'click', function () {
    functions.formHandler();
  });
});

/*=============================================
=              INITS & VARIABLES              =
=============================================*/

// const mainNavSearch = document.getElementById('main-navbar__search');
// const mainNavSearchResults = document.getElementById(
//   'main-navbar__search-results'
// );

/*=============================================
=                   EVENTS                    =
=============================================*/

// if(functions.domElementExists("#main-navbar__search")){
//   mainNavSearch.addEventListener('click', (e) => {
//     mainNavSearchResults.classList.add('active');
//   });
//
//   window.addEventListener('click', (e) => {
//     if (e.target.parentElement) {
//       if (!e.target.parentElement.classList.contains('main-navbar__search')) {
//         mainNavSearchResults.classList.remove('active');
//       }
//     } else {
//       mainNavSearchResults.classList.remove('active');
//     }
//   });
// }
// }
