<template>
  <div>
      <div class="uk-child-width-1-2@m uk-grid-medium mb-4" uk-grid="masonry: true">
          <user-adress-card
            v-for="address in localAddresses"
            :init-address="address"
            :default-delivery="localDefaultDelivery"
            :default-billing="localDefaultBilling"
            :countries="countries"
            :routes="routes"
            :translations="translations"
            :key="address.id"
          >
          </user-adress-card>
      </div>

      <a href="javascript:void(0)" @click.prevent="handleStartEdit" class="uk-button uk-button-primary"><i class="fa-solid fa-plus me-1"></i>{{ translations.new_address }}</a>

      <div id="new-address-modal" class="uk-modal-container uk-flex-top" uk-modal="esc-close: false; bg-close: false; keyboard: false;">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <form class="form-custom form-custom-white" @submit.stop.prevent="handleUpdate" v-on:keyup.enter.stop.prevent="handleUpdate">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2@s">
              <label class="uk-form-label" for="streetname">{{ translations.streetname }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="streetname" type="text" :placeholder="translations.streetname" v-model="newAddress.street">
                <div class="input-errors" v-if="newAddressErrors.street">
                  <p v-for="error in newAddressErrors.street">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@s">
              <label class="uk-form-label" for="number">{{ translations.housenr }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="number" type="text" :placeholder="translations.housenr" v-model="newAddress.number">
                <div class="input-errors" v-if="newAddressErrors.number">
                  <p v-for="error in newAddressErrors.number">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@s">
              <label class="uk-form-label" for="number_addon">{{ translations.number_addon_short }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="number_addon" type="text" :placeholder="translations.number_addon_short" v-model="newAddress.number_addon">
                <div class="input-errors" v-if="newAddressErrors.number_addon">
                  <p v-for="error in newAddressErrors.number_addon">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@s">
              <label class="uk-form-label" for="busnumber">{{ translations.busnr }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="busnumber" type="text" placeholder="Busnr." v-model="newAddress.busnumber">
                <div class="input-errors" v-if="newAddressErrors.busnr">
                  <p v-for="error in newAddressErrors.busnr">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-4@s">
              <label class="uk-form-label" for="postalcode">{{ translations.postalcode }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="postalcode" type="text" :placeholder="translations.postalcode" v-model="newAddress.postalcode">
                <div class="input-errors" v-if="newAddressErrors.postalcode">
                  <p v-for="error in newAddressErrors.postalcode">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-2@s">
              <label class="uk-form-label" for="commune">{{ translations.commune }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" id="commune" type="text" :placeholder="translations.commune" v-model="newAddress.commune">
                <div class="input-errors" v-if="newAddressErrors.commune">
                  <p v-for="error in newAddressErrors.commune">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-4@s">
              <label class="uk-form-label" for="country">{{ translations.country }}</label>
              <div class="uk-form-controls">
                <select name="company-type" id="country" class="custom-select uk-width-1-1" v-model="newAddress.countryId">
                  <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
                </select>
                <div class="input-errors" v-if="newAddressErrors.country_id">
                  <p v-for="error in newAddressErrors.country_id">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1 mb-3">
              <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <p><span class="uk-text-bold">{{ translations.default_address }}s</span></p>

                <label class="custom-checkbox">
                  <input class="uk-checkbox me-2 mt-1" type="checkbox" name="default_delivery" v-model="newAddress.defaultDelivery">
                  <p class="my-0">{{ translations.default_delivery }}</p>
                </label>
                <div class="input-errors" v-if="newAddressErrors.default_delivery">
                  <p v-for="error in newAddressErrors.default_delivery">{{ error }}</p>
                </div>

                <label class="custom-checkbox">
                  <input class="uk-checkbox me-2 mt-1" type="checkbox" name="default_billing" v-model="newAddress.defaultBilling">
                  <p class="my-0">{{ translations.default_billing }}</p>
                </label>
                <div class="input-errors" v-if="newAddressErrors.default_billing">
                  <p v-for="error in newAddressErrors.default_billing">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1">
              <div class="d-flex justify-content-end">
                <button class="uk-button uk-button-secondary me-2" @click.prevent="cancelUpdate">{{ translations.cancel }}</button>
                <button class="uk-button uk-button-primary" @click.prevent="handleUpdate">
                  <div class="loader" v-if="newIsLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
                  {{ translations.save }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAdresCard from "./UserAdressCard";
  import { EventBus } from "../EventBus";
  import Vue from 'vue';
  import UIkit from "uikit";

  export default {
    name: "UserAdresses",
    mounted(){
        EventBus.$on("REMOVE_ADDRESS", address => {
          Vue.delete(this.adresses, this.adresses.indexOf(address));
          this.adresses = Object.assign({}, this.adresses);
        });

        EventBus.$on("DEFAULT_BILLING_ADDRESS_UPDATE", addressId => {
          this.localDefaultBilling = addressId;
        });

        EventBus.$on("DEFAULT_DELIVERY_ADDRESS_UPDATE", addressId => {
          this.localDefaultDelivery = addressId;
        });
    },
    data() {
      return {
        localAddresses: this.adresses,
        localDefaultDelivery: this.defaultDelivery,
        localDefaultBilling: this.defaultBilling,
        newAddress: this.initNewAddress(),
        newAddressErrors: {},
        newIsLoading: false
      }
    },
    methods: {
      initNewAddress(){
        return {
          street: '',
          number: '',
          numberAddon: '',
          busnumber: '',
          postalcode: '',
          commune: '',
          countryId: '',
          defaultDelivery: false,
          defaultBilling: false
        }
      },
      handleStartEdit(){
        UIkit.modal(document.getElementById("new-address-modal")).show();
      },
      cancelUpdate(){
        UIkit.modal(document.getElementById("new-address-modal")).hide();
        this.newAddress = this.initNewAddress();
        this.newAddressErrors = {};
      },
      handleUpdate(){
        UIkit.modal(document.getElementById("new-address-modal")).show();
        this.newIsLoading = true;

        let data =  {
          street: this.newAddress.street,
          number: this.newAddress.number,
          number_addon: this.newAddress.numberAddon,
          busnumber: this.newAddress.busnumber,
          postalcode: this.newAddress.postalcode,
          commune: this.newAddress.commune,
          country_id: this.newAddress.countryId,
          default_delivery: this.newAddress.defaultDelivery,
          default_billing: this.newAddress.defaultBilling
        };

        window.axios.post(this.routes.create, data)
            .then(res => {
              let resData = res.data;

              if(resData.messages) {
                resData.messages.forEach(message => {
                  EventBus.$emit('NOTIFY', message)
                });
              }

              if(resData.address) {
                this.localAddresses.push(resData.address);
              }

              if(resData.defaultBilling) {
                this.localDefaultBilling = resData.defaultBilling;
              }

              if(resData.defaultDelivery) {
                this.localDefaultDelivery = resData.defaultDelivery;
              }

              if(resData.success) {
                UIkit.modal(document.getElementById("new-address-modal")).hide();
                this.newAddress = this.initNewAddress();
                this.newAddressErrors = {};
              }

              this.newIsLoading = false;
            })
            .catch(err => {
              this.newAddressErrors = err?.response?.data ?? {};
              this.newIsLoading = false;
            })
      },
    },
    components: {UserAdresCard},
    props: {
      adresses: {
        type: Object|Array,
        required: true
      },
      defaultDelivery: {
        type: Number,
        required: false,
        default: null
      },
      defaultBilling: {
        type: Number,
        required: false,
        default: null
      },
      countries: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      },
      translations: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>