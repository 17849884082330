var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom" }, [
    _c(
      "ul",
      { staticClass: "sidebar", attrs: { "uk-accordion": "multiple: true" } },
      [
        _vm.categories.length > 0
          ? _c("filter-sidebar-categories", {
              attrs: {
                categories: _vm.categories,
                activeCategories: _vm.activeCategories,
                translations: _vm.translations,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.sizes, function (sizegroup) {
          return _c("filter-sidebar-sizes-group", {
            key: sizegroup.id,
            attrs: { sizegroup: sizegroup, "active-sizes": _vm.activeSizes },
            model: {
              value: _vm.activeSizes,
              callback: function ($$v) {
                _vm.activeSizes = $$v
              },
              expression: "activeSizes",
            },
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.dynamicfilters, function (filter) {
          return _c("filter-sidebar-dynamic-filters", {
            key: filter.id,
            attrs: {
              filter: filter,
              "active-filters": _vm.activeDynamicalFilters,
            },
            model: {
              value: _vm.activeDynamicalFilters,
              callback: function ($$v) {
                _vm.activeDynamicalFilters = $$v
              },
              expression: "activeDynamicalFilters",
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }